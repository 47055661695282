import { MenuList } from 'components/menuList/MenuList';
import { useGlobalContentContext } from 'context/GlobalContentContext';
import Link from 'next/link';
import style from './MainFooter.module.scss';

export type MainFooterType = { showMenu: boolean; showSocial: boolean; reduceHeight?: boolean };
export const MainFooter = ({ showMenu, showSocial, reduceHeight = false }: MainFooterType): JSX.Element => {
  const { data } = useGlobalContentContext();
  const { socials } = data;

  const toggleInfoDisplay = () => {
    // @ts-ignore
    window.OneTrust && window.OneTrust.ToggleInfoDisplay()
  }

  const socialState = showSocial ? style.socialListIsShowing : style.socialListIsHidden;
  return (
    <div className={style.container}>
      <div className={`${style.mainItems} ${reduceHeight ? style.mainItemsSmallMargin : ''}`}>
        <ul className={`${style.socialList} ${socialState}`}>
          {Object.keys(socials).map((key, index) => {
            const url = socials[key];
            {
              return (
                <li key={index} className={`${style.socialListItem}`}>
                  <a href={url} className={`${style.link} `} rel="noreferrer" target="_blank">
                    {key}
                  </a>
                </li>
              );
            }
          })}
        </ul>
        {showMenu && <MenuList className={style.menuList} isAtBottom={true} />}
      </div>
      <div className={style.bottomItems}>
        <span>&copy;{new Date().getFullYear()} Copyright{' '}</span>
        <Link href="/privacy/">
          <a className={style.bottomLink}>Privacy</a>
        </Link>
        <Link href="/terms/">
          <a className={style.bottomLink}>Cookie Policy</a>
        </Link>
        <span
          className={`${style.bottomLink} ${style.cookieSettingsLink}`}
          role="button" onClick={toggleInfoDisplay}
        >
          Cookie Settings
        </span>
      </div>
    </div>
  );
};
