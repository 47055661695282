import style from './MenuList.module.scss';
import Link from 'next/link';
import { useRouter } from 'next/router';

export const MENU_ITEMS = [
  { href: '/', label: 'Work' },
  { href: '/news', label: 'News' },
  { href: '/careers', label: 'Careers' },
  { href: '/studio', label: 'Studio' },

  // { href: '/', label: 'Home' },
  // { href: '/about', label: 'About' },
  // { href: '/news', label: 'Pinboard' },
  // { href: '/careers', label: 'Careers' },
  // { href: '/contact', label: 'Contact' },
];

export type MenuListType = { className: string; isAtBottom: boolean };

export const MenuList = (props: MenuListType): JSX.Element => {
  const state = props.isAtBottom ? style.menuListAtBottom : '';
  const router = useRouter();

  return (
    <ul className={`${style.menuList} ${props.className} ${state}`}>
      {MENU_ITEMS.map(({ href, label }) => {
        const pathToCheck = router.pathname;
        const isSelectedState = pathToCheck === href ? style.linkIsSelected : '';

        return (
          <li key={label} className={`${style.menuListItem}`}>
            <Link href={href}>
              <a className={`${style.link} ${isSelectedState}`}>{label}</a>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};
