import { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap/dist/gsap';
import { Draggable } from 'gsap/dist/Draggable';
import { InertiaPlugin } from 'gsap/dist/InertiaPlugin';

import style from './ProjectSelectorCarousel.module.scss';
import { ProjectType } from 'pages/work/[slug]';
import { ImageBlock } from 'components/general/blocks/imageBlock/ImageBlock';
import { getCSSVar, getDomPos, getGridColumnWidth, isBrowser } from 'utils/helpers';
import { useRouter } from 'next/router';

if (isBrowser()) {
  gsap.registerPlugin(Draggable, InertiaPlugin);
}

export type ProjectSelectorAnimationType =
  | {
      id: 'show-all';
      cameFromDefaultPage: boolean;
      slug?: string;
      force?: boolean;
      onComplete: () => void;
    }
  | {
      id: 'reset-to-all';
      slug?: string;
      showMenu?: boolean;
      skipAnimation?: boolean;
      force?: boolean;
      onComplete: () => void;
    }
  | {
      id: 'select-project';
      cameFromHome: boolean;
      slug: string;
      force?: boolean;
      onComplete: () => void;
    }
  | { id: 'reset-to-project'; slug: string; force?: boolean; onComplete: () => void };
export type ProjectSelectorCarouselType = {
  projects: ProjectType[];
  onProjectSelect: (p: ProjectType) => void;
  animation: ProjectSelectorAnimationType;
  showIntroAnimation: boolean;
};

export const ProjectSelectorCarousel = ({
  projects,
  onProjectSelect,
  animation,
  showIntroAnimation,
}: ProjectSelectorCarouselType): JSX.Element => {
  // we are deliberately not useing "useState" as we wat to minimise re-renders
  const slidesInner = useRef<HTMLDivElement>(null);
  const slideTitleHTMLRef = useRef<HTMLDivElement>(null);
  const slideTitleRef = useRef<string>('');
  const prevGrowthPctRef = useRef<number>();
  const carouselTweenRef = useRef<any>();
  const carouselProgressRef = useRef<number>();
  const currentAnimationIdRef = useRef<string>();
  const scrollEnabledRef = useRef<boolean>();
  const scrollSpeedRef = useRef<number>(0);
  const scrollSpeedProxyRef = useRef<{ speed: number }>({ speed: 0 });
  const router = useRouter();

  const [canClickOnCarousel, setCanClickOnCarousel] = useState<boolean>(false);
  // const canClickOnCarousel = true;
  // const setCanClickOnCarousel = (val: any) => {};

  const progressWrap = gsap.utils.wrap(0, 1);
  const yTravelLogo = `-100%`;
  const tweenWrapSize = 3;

  const updateProgress = (pct) => {
    carouselProgressRef.current = pct;
    const wrapped = progressWrap(pct);
    carouselTweenRef.current.progress(wrapped);
  };

  const createCarouselTween = () => {
    const numSlides = projects.length;
    const xPercentWrap = gsap.utils.wrap(-100 * tweenWrapSize, (numSlides - tweenWrapSize) * 100);
    return gsap.to('.js-slide', {
      xPercent: '+=' + numSlides * (100 * tweenWrapSize),
      duration: 1,
      ease: 'none',
      paused: true,
      repeat: -1,
      modifiers: {
        xPercent: xPercentWrap,
      },
    });
  };
  const setScaleBasedOnSpeed = (
    rawSpeed: number,
    quickAnimation: boolean,
    forceReset: boolean,
    onComplete?: () => void,
  ) => {
    const speed = Math.abs(rawSpeed);
    const pct = Math.max(0.45, 1 - speed);
    if (prevGrowthPctRef.current === undefined) return;

    const defaultTime = 1;

    const pageTransitionDelay = getCSSVar('--pageTransitionDelay') / 1000;
    // if it's forced, we need to be quick in order to sync up with the page transitions
    const duration = forceReset ? pageTransitionDelay * 0.2 : defaultTime;

    const scale = forceReset ? 1 : pct;

    gsap.to('.js-scale-container', {
      scale: scale,
      transformOrigin: `center top`,
      duration: quickAnimation && !forceReset ? 1 : duration,
      overwrite: 'auto',
      onComplete: () => {
        onComplete && onComplete();
      },
    });
    prevGrowthPctRef.current = scale;
  };

  useEffect(() => {
    const showLogoOnly = router.pathname !== '/' && scrollY > window.innerHeight;
    gsap.set('.js-typo', { y: showLogoOnly ? 0 : yTravelLogo });

    return () => {};
  }, []);
  // end global route based animation

  const enableScroll = (val: boolean) => {
    scrollEnabledRef.current = val;
    if (!val) hideTitle();
  };

  const moveSlideToIndex = (
    projectIndex: number,
    duration: number = 0,
    direction: 'left' | 'right',
  ) => {
    const totalSlides = projects.length;
    const halfSlides = Math.floor(totalSlides / 2);
    const stepPct = 1 / totalSlides;
    const baseTo = (stepPct * projectIndex) / tweenWrapSize;
    // progress from 0 -1 means we wrap the animation 3x (tweenWrapSize)
    const from = carouselTweenRef.current.progress() % tweenWrapSize;

    const progress = { pct: from };
    const slideWidth = window.innerWidth * (getCSSVar('--slideWidth') / 100);
    const fullWidth = slideWidth * totalSlides;
    const paddingLeft = window.innerWidth * 0.12;
    const pixelPct = paddingLeft / fullWidth / tweenWrapSize;

    const toCandidates = [...Array(tweenWrapSize).keys()].map(
      (x) => 1 - (baseTo + x * (1 / (totalSlides * tweenWrapSize)) * totalSlides) + pixelPct,
    );

    const rawPct = toCandidates.reduce(function (prev, curr) {
      return Math.abs(curr - from) < Math.abs(prev - from) ? curr : prev;
    });

    gsap.to(progress, {
      ease: 'power1.inOut',
      pct: rawPct,
      duration,
      onUpdate: () => {
        updateProgress(progress.pct);
      },
    });
  };

  const animateToSlug = () => {
    // console.log({ animation });
    if (
      !animation.force &&
      currentAnimationIdRef.current === animation.id &&
      // the reset-to-all is the default, but it doesn't tell us wether or not we show the menu
      !(animation.id === 'reset-to-all')
    ) {
      //  we don't want to override exsiting animations, casuing stutters
      // console.log('blocked');
      return;
    }

    currentAnimationIdRef.current = animation.id;
    const slideToSelect = `[data-slug="${animation.slug}"]`;
    // the position can be scaled if a user clicks when it's still animating
    // therefore we want to calculate the pos manually instead of grabbing the DOM value
    // unscaled parent based position is being the thing that creates these

    const slideToSelectPos = getDomPos(slideToSelect);

    const slidesToLeft: string[] = [];
    const slidesToRight: string[] = [];

    if (slideToSelectPos !== undefined) {
      projects.forEach((proj, index) => {
        const domPos = getDomPos(`[data-slug="${proj.slug}"]`);
        if (domPos === undefined) return;
        if (domPos < slideToSelectPos) slidesToLeft.push(`[data-index="${index}"]`);
        if (domPos > slideToSelectPos) slidesToRight.push(`[data-index="${index}"]`);
      });
    }

    const slideWidth = window.innerWidth * (getCSSVar('--slideWidth') / 100);
    const pageTransitionTime = getCSSVar('--pageTransitionTime') / 1000;
    const pageTransitionDelay = getCSSVar('--pageTransitionDelay') / 1000;

    const columnSpread = 9;
    const columnSize = columnSpread * getGridColumnWidth();
    const columnCenter = columnSize / 2;
    const centerOffset = columnCenter - slideWidth / 2;
    // take into account that the container is scaling

    const selectedSlideChild = `${slideToSelect} .js-slide-child`;

    let positionSlidesDuration = 0.5;
    const xTravel = -300;
    const ease = 'power1.inOut';

    const tl = gsap.timeline();

    const indexOfProject = Math.max(
      0,
      projects.findIndex((p) => p.slug === animation.slug),
    );

    // DEV NOTE: we are animating the children of the selected slide
    // this has to do with the wrapping of their positions
    // it became to complex to work with the wrapping during the animation setup
    setCanClickOnCarousel(false);
    const placeHolder = () => {};
    animation.onComplete = animation.onComplete || placeHolder;

    switch (animation.id) {
      case 'reset-to-all':
        // ---------------------------
        // land on home, or
        // scrolled a project page to bottom
        // ---------------------------
        enableScroll(true);
        // reset all the slides
        gsap.set(['.js-slide-child'], {
          x: 0,
          alpha: 1,
          overwrite: true,
        });
        if (animation.showMenu) {
          gsap.to(['.js-top-menu'], { autoAlpha: 1 });
          gsap.to(['.js-sidebar'], { x: 0 });
          gsap.to(['.js-eye'], { x: 0 });
          gsap.set('.js-typo', { y: yTravelLogo });
          gsap.set('.js-carousel-footer', { y: 200, autoAlpha: 0 });
        } else {
          gsap.killTweensOf(['.js-top-menu', '.js-sidebar', '.js-eye']);
          const duration = animation.skipAnimation ? 0 : 0.5;
          gsap.to(['.js-top-menu'], { duration, autoAlpha: 0 });
          gsap.to(['.js-sidebar'], { duration, x: xTravel });
          gsap.to(['.js-eye'], { duration, x: xTravel });
          gsap.set('.js-typo', { y: 0 });
          gsap.set('.js-carousel-footer', { y: 0, autoAlpha: 1 });
        }
        setCanClickOnCarousel(true);

        animation.onComplete();
        break;

      case 'select-project':
        // ---------------------------
        // home > project
        // project > project
        // ---------------------------
        //
        // cancel any previous animation on the slides
        // fixes project > home> same project bugs when animationsa are not quite finished
        gsap.killTweensOf('.js-slide-child');
        hideTitle();
        //no more scrolling
        enableScroll(false);
        gsap.killTweensOf(scrollSpeedProxyRef.current);
        setScaleBasedOnSpeed(0, false, true, () => {
          // we want to animate the slides before the page transition starts
          // if we want to overlap, we can get a factor of that value
          const fromHome = animation.cameFromHome;
          // we already used up 0.2 to setScaleBasedOnSpeed, we have max 0.8 left to ctach up
          positionSlidesDuration = pageTransitionDelay * (fromHome ? 0.8 : 1);
          hideTitle();

          // TODO: can be 0 on Safari, maybe we do it with a set percentage?
          const unscaledSLidePos = getDomPos(slideToSelect);
          const xPos = (unscaledSLidePos || 0) - centerOffset;
          hideTitle();

          // MOVE THE SLIDER TO thE COrreCt positioN
          const direction = unscaledSLidePos < window.innerWidth * 0.35 ? 'right' : 'left';
          moveSlideToIndex(indexOfProject, positionSlidesDuration, direction);

          // console.log({ stepPct, tweenWrapSize, totalSlides });

          // END MOVE SLIDER POS

          const scrollDuration = 0;
          tl.addLabel('start', scrollDuration);
          // set the position of the seleced slide child to selected state

          tl.to(
            selectedSlideChild,
            {
              x: 0, // -xPos,
              alpha: 1,
              duration: positionSlidesDuration,
              ease,
              onComplete: () => {
                animation.onComplete();
              },
            },
            'start',
          );
          // move the remaining slides to the left/right of selected slide
          tl.to(
            slidesToLeft,
            {
              // x: -(xPos + 300),
              alpha: 0,
              duration: positionSlidesDuration,
              ease,
            },
            'start',
          );
          tl.to(
            slidesToRight,
            {
              // x: 300,
              alpha: 0,
              duration: positionSlidesDuration,
              ease,
            },
            'start',
          );

          // position label at END of previous animation
          tl.addLabel('slidesDone', '>');

          const introDuration = getCSSVar('--projectIntroTime') / 1000;

          const sideDuration = fromHome ? 1 : introDuration;
          const sideDelay =
            pageTransitionDelay +
            (fromHome ? pageTransitionTime : 0) +
            introDuration -
            sideDuration;

          tl.to(['.js-top-menu'], { autoAlpha: 1 });
          // move the big logo slightly before the end of the slides animations
          // let the start/end of the animation sync up with the page transitions
          // so keep outside of the timeline
          gsap.to('.js-typo', {
            y: yTravelLogo,
            delay: pageTransitionDelay * 0.95,
            duration: pageTransitionTime * 1.85,
          });
          gsap.to(['.js-sidebar'], {
            x: 0,
            duration: sideDuration,
            delay: sideDelay,
          });
          gsap.to(['.js-eye'], {
            x: 0,
            duration: sideDuration,
            delay: sideDelay,
          });
          tl.to(
            '.js-carousel-footer',
            { duration: positionSlidesDuration, y: 200, autoAlpha: 0 },
            'start',
          );
        });
        break;
      case 'reset-to-project':
        // ---------------------------
        // land on project, or
        // scrolled a project page to top
        // ---------------------------
        gsap.killTweensOf(scrollSpeedProxyRef.current);
        enableScroll(false);

        setScaleBasedOnSpeed(0, false, true);

        // we want to animate the slides before the page transition starts
        // if we want to overlap, we can get a factor of that value

        //-------reset the whole slider AND the slide child elements-------//
        // set the position of the seleced slide child to selected state
        // Dev Note: we need to take into account that the tween wraps the "tweenWrapSize"
        const fullWidth = slideWidth * projects.length;
        const slidePosOnSlider = indexOfProject * -slideWidth + centerOffset;
        const pixelPct = slidePosOnSlider / fullWidth / tweenWrapSize;

        // updateProgress(pixelPct);
        // MOVE THE SLIDER TO thE COrreCt positioN
        const scrollDuration = 0;
        const direction = 'left';
        moveSlideToIndex(indexOfProject, scrollDuration, direction);
        // DEV note: little hack because when we land on a deeplink, the GSAP target isn't available quite yet.
        // a little timeout is enough
        setTimeout(() => {
          // updateProgress(pixelPct);
          moveSlideToIndex(indexOfProject, scrollDuration, direction);
          animation.onComplete();
        }, 100);
        gsap.set(['.js-slide-child'], { x: 0, alpha: 0 });
        //-----------------------------------------------------------------//

        gsap.set(selectedSlideChild, { alpha: 1 });
        // move the remaining slides to the left/right of selected slide
        gsap.set(slidesToLeft, { x: -300 });
        gsap.set(slidesToRight, { x: 300 });

        gsap.to(['.js-top-menu'], { autoAlpha: 1 });
        gsap.to(['.js-sidebar'], { x: 0 });
        gsap.to(['.js-eye'], { x: 0 });
        gsap.set('.js-typo', { y: yTravelLogo });
        gsap.set('.js-carousel-footer', { y: 0, autoAlpha: 0 });

        break;

      case 'show-all':
        // ---------------------------
        // project > home
        // page > home
        // ---------------------------
        // keep delay at zero so we don't have any waiting
        //the PageTransition is setting the autoAlpha:0 instantly due to nextjs bug
        setCanClickOnCarousel(true);
        const baseDelay = 0;
        const delay = baseDelay + 0.25;
        const showAllDuration = positionSlidesDuration * 2;
        // set reset all .js-slide-child to start position
        gsap.to('.js-slide-child', {
          x: 0,
          alpha: 1,
          delay,
          duration: showAllDuration,
          ease,
          onComplete: () => {
            enableScroll(true);
            animation.onComplete();
          },
        });
        gsap.to(['.js-top-menu'], {
          delay: baseDelay,
          duration: positionSlidesDuration,
          autoAlpha: 0,
        });
        gsap.to(['.js-sidebar'], { delay: 0, duration: showAllDuration, x: xTravel });
        gsap.to(['.js-eye'], { delay: 0, duration: showAllDuration, x: xTravel });
        gsap.to('.js-typo', { duration: positionSlidesDuration, delay, y: 0 });
        gsap.to('.js-carousel-footer', {
          delay: baseDelay,
          duration: positionSlidesDuration,
          autoAlpha: 1,
          y: 0,
        });

        break;
    }

    return;
  };
  useEffect(() => {
    document.body.dataset.carousel = animation.id;
    animateToSlug();
  }, [animation, projects]);

  const scrollUpdate = () => {
    scrollSpeedRef.current = scrollSpeedProxyRef.current.speed;
    let baseScroll = 0;
    if (carouselProgressRef.current !== undefined) {
      baseScroll = carouselProgressRef.current + scrollSpeedRef.current / 500000;
    }
    setScaleBasedOnSpeed(scrollSpeedRef.current / 8000, true, false);
    updateProgress(baseScroll);
    const showShowTabletTitle = scrollSpeedRef.current === 0;
    gsap.to('.js-slide-title-tablet', { autoAlpha: showShowTabletTitle ? 1 : 0 });
  };

  const onDrag = (speed) => {
    if (!scrollEnabledRef.current) {
      // gsap.killTweensOf(scrollSpeedProxyRef.current);
      return;
    }
    hideTitle();
    scrollSpeedProxyRef.current.speed = speed;
    scrollUpdate();
  };
  const onScroll = (e) => {
    if (!scrollEnabledRef.current) {
      // gsap.killTweensOf(scrollSpeedProxyRef.current);
      return;
    }

    // set it directly and animate back to zero
    scrollSpeedProxyRef.current.speed += -e.deltaX + e.deltaY * -1;
    //update speed first
    hideTitle();

    gsap.to(scrollSpeedProxyRef.current, {
      speed: 0,
      duration: 0.5,
      // ease: 'none',
      overwrite: true,
      onUpdate: () => {
        scrollUpdate();
      },
    });
  };

  useEffect(() => {
    if (showIntroAnimation) {
      const delay = 1;
      gsap.to(scrollSpeedProxyRef.current, {
        speed: -700,
        duration: delay,
        ease: 'power2.inOut',
        onUpdate: scrollUpdate,
      });
      gsap.to(scrollSpeedProxyRef.current, {
        speed: 0,
        delay,
        duration: 1,
        ease: 'power2.inOut',
        onUpdate: scrollUpdate,
      });
    }
  }, [showIntroAnimation]);

  useEffect(() => {
    if (prevGrowthPctRef.current === undefined) prevGrowthPctRef.current = 0;
    if (carouselProgressRef.current === undefined) carouselProgressRef.current = 0;

    carouselTweenRef.current = createCarouselTween();

    const proxy = document.createElement('div');
    const speedTracker = InertiaPlugin.track(proxy, 'x')[0];

    const align = () => {
      onDrag(speedTracker.get('x') * 0.51);
    };

    const draggable = Draggable.create(proxy, {
      trigger: slidesInner.current,
      type: 'x',
      onPress: hideTitle,
      onDrag: align,
      onThrowUpdate: align,
      inertia: true,
      onThrowComplete: () => {
        onDrag(0);
        gsap.set(proxy, { x: 0 });
      },
    })[0];

    // DEV NOTE: look at this if we see unexpected reset of the positions on re-render
    gsap.set('.js-slide', {
      xPercent: (i: number) => {
        const offset = i * 100;
        return offset;
      },
    });

    document.addEventListener('wheel', onScroll);

    return () => {
      draggable.kill();
      carouselTweenRef.current && carouselTweenRef.current.kill();
      document.removeEventListener('wheel', onScroll);
    };
  }, [slidesInner.current]);

  const onSlideMouseMove = (e) => {
    const el: HTMLDivElement = e.currentTarget;
    const duration = 0.2;
    const slideRect = el.getBoundingClientRect();
    if (!scrollEnabledRef.current) return;
    //only show the rollover if the slide is fully visible
    if (slideRect.x < 0 || slideRect.x + slideRect.width > window.innerWidth) return;
    // don't update if we are already the same title
    if (slideTitleRef.current === el.dataset.title) return;

    if (Math.abs(scrollSpeedRef.current) <= 0.01) {
      slideTitleRef.current = el.dataset.title || '';
      const tl = gsap.timeline();
      tl.to(['.js-slide-title'], {
        // y: 0,
        autoAlpha: 0,
        duration: slideTitleRef.current === '' ? 0 : duration,
        onComplete: () => {
          if (!slideTitleHTMLRef.current) return;
          slideTitleHTMLRef.current.innerHTML = slideTitleRef.current;
        },
      });
      tl.set('.js-slide-title', { x: slideRect.x, y: 0, autoAlpha: 0, width: slideRect.width });
      tl.to('.js-slide-title', { y: '100%', autoAlpha: 1, duration });
    }
  };

  const hideTitle = () => {
    slideTitleRef.current = '';
    gsap.to(['.js-slide-title', '.js-slide-title-tablet'], { autoAlpha: 0, duration: 0.1 });
  };
  const onSlideMouseLeave = () => {
    slideTitleRef.current = '';
    const duration = 0.2;
    if (Math.abs(scrollSpeedRef.current) <= 0.01) {
      gsap.to('.js-slide-title', { autoAlpha: 0, duration });
    } else {
      hideTitle();
    }
  };

  return (
    <section className={style.row}>
      <div className={style.slidesContainer}>
        <div ref={slidesInner} className={style.slidesInner}>
          <div className={`${style.scaleContainer} js-scale-container`}>
            {projects.map((p, index) => {
              return (
                <div
                  key={`${index}-${p.slug}-img`}
                  data-slug={p.slug}
                  className={`${style.slide} js-slide`}
                >
                  <div
                    className={`${style.slideInner} js-slide-child`}
                    data-index={index}
                    data-title={p.title}
                    onClick={() => {
                      if (canClickOnCarousel) {
                        hideTitle();
                        router.push('/work/' + p.slug);
                      }
                    }}
                    onMouseMove={onSlideMouseMove}
                    onMouseLeave={onSlideMouseLeave}
                  >
                    <div className={`${style.slideImage}`}>
                      <ImageBlock
                        desktop={{
                          src: p.hero.image,
                          layout: 'fill',
                          objectFit: 'cover',
                          loading: 'eager',
                          sizes: '35vw',
                        }}
                        alt={p.hero.alt_text}
                      />
                    </div>
                    <div className={`${style.slideTabletTitle} `}>
                      <span
                        className={`js-slide-title-tablet`}
                        dangerouslySetInnerHTML={{ __html: p.title }}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {/* <div  className={`${style.slideTitleContainer} `}> */}
          <div className={`${style.slideDesktopTitle} `}>
            <span ref={slideTitleHTMLRef} className={`${style.slideTitle} js-slide-title`}></span>
          </div>
          {/* </div> */}
        </div>
      </div>
    </section>
  );
};
